<template>
  <jc-form :model="form" :rules="rules" ref="form" :label-width="labelWidth" class="jc-form-tiny-width">
    <jc-form-item prop="mobile" label="手机号：">
      <jc-input v-model.trim="form.mobile" type="text" placeholder="请输入手机号"></jc-input>
      <div v-html="phoneRule" v-show="phoneRule"></div>
    </jc-form-item>
    <template v-if="!display">
      <jc-form-item prop="" label="手机验证码：" v-if="!!infoData.mobile && infoData.mobile === form.mobile">
        <div style="display:flex;justify-content:space-between">
          <jc-input
            v-model.trim="form.smsCode"
            type="text"
            placeholder="号码已验证"
            style="width:210px"
            disabled
          ></jc-input>
          <jc-button type="primary" disabled>获取手机验证码</jc-button>
        </div>
      </jc-form-item>
      <jc-form-item prop="smsCode" label="手机验证码：" v-else>
        <div style="display:flex;justify-content:space-between">
          <jc-input
            v-model.trim="form.smsCode"
            type="text"
            placeholder="请输入手机验证码"
            style="width:210px"
          ></jc-input>
          <jc-button type="primary" @click="getSmsCode()">获取手机验证码</jc-button>
        </div>
      </jc-form-item>
    </template>
    <jc-form-item prop="emergencyCall" label="应急电话号码：">
      <jc-input v-model.trim="form.emergencyCall" type="text" placeholder="请输入手机号"></jc-input>
      <div v-html="emergencyPhoneRule" v-show="emergencyPhoneRule"></div>
    </jc-form-item>
    <jc-form-item prop="email" label="电子邮箱地址：">
      <jc-input v-model.trim="form.email" type="text" placeholder="请输入邮箱地址"></jc-input>
    </jc-form-item>
    <jc-form-item v-if="isShowField('principalRemark')" label="主体信息备注：">
      <jc-input v-model.trim="form.remark" type="textarea"></jc-input>
    </jc-form-item>
  </jc-form>
</template>
<script>
import { OWNER_PERSONAL_RULE } from '@/views/service/rules'
import { sendMobileSms, queryRule } from '@/service/record-api'
export default {
  data() {
    return {
      form: {
        mobile: '',
        email: '',
        remark: '',
        emergencyCall: '',
        smsCode: ''
      },
      phoneRule: '',
      emergencyPhoneRule: '',
      rules: OWNER_PERSONAL_RULE
    }
  },
  props: {
    infoData: {
      type: Object
    },
    labelWidth: String
  },
  components: {},
  mixins: [],
  methods: {
    //获取文本规则
    async getTextRules() {
      try {
        let { data } = await queryRule({
          type: this.role == 5 ? 0 : 1,
          provinceCode: this.infoData.provinceCode
        })
        this.phoneRule = data.find(item => item.fieldCode === 1) ? data.find(item => item.fieldCode === 1).content : ''
        this.emergencyPhoneRule = data.find(item => item.fieldCode === 2)
          ? data.find(item => item.fieldCode === 2).content
          : ''
      } catch (err) {
        console.log(err)
      }
    },
    getInit() {
      this.form.mobile = this.infoData.mobile || ''
      this.form.emergencyCall = this.infoData.emergencyCall || ''
      this.form.email = this.infoData.email || ''
      this.form.remark = this.infoData.remark || ''
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate(valid => {
          if (valid) {
            resolve(this.form)
          } else {
            reject(new Error('submit false'))
          }
        })
      })
    },
    async getSmsCode() {
      if (!this.form.mobile) return

      this.$refs.form.validateField('mobile', async errorMsg => {
        if (!errorMsg) {
          try {
            const result = await sendMobileSms({ mobile: this.form.mobile })
            if (!result.error) {
              this.$message.success('发送成功')
            } else {
              this.$message.error(result.error)
            }
          } catch (error) {
            this.form.smsCode = ''
            this.$message.error(error.message)
          }
        }
      })
    }
  },
  mounted() {},
  async created() {
    await this.getTextRules()
    this.getInit()
  },
  watch: {
    infoData() {
      this.getInit()
    }
  },
  computed: {},
  filters: {}
}
</script>
